<template>
  <a-spin :spinning="spinning">
    <div class="modal-header" style="margin-bottom: 10px">
      <div class="modal-title">
        <a-icon type="medicine-box" />
        <span span v-if="formModel.id"
          >{{ mode === "edit" ? "修改" : "" }}1 {{ formModel.woName }}</span
        >
        <span span v-if="!formModel.id">创建工单</span>
      </div>
    </div>

    <a-form-model
      :rules="validModel"
      ref="ruleForm"
      :layout="form.layout"
      :model="formModel"
      v-bind="formItemLayout"
    >
      <a-form-model-item label="工单编号" prop="woNo">
        <a-input
          placeholder="请输入工单编号"
          v-model="formModel.woNo"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item label="工单名称" prop="woName">
        <a-input
          placeholder="请输入工单名称"
          v-model="formModel.woName"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item label="工价" prop="price">
        <a-input placeholder="请输入工价" v-model="formModel.price"></a-input>
      </a-form-model-item>

      <a-form-model-item label="计价单位" prop="priceBase">
        <a-select
          v-model="formModel.priceBase"
          defaultVlaue=""
          :options="options('SalaryType')"
        ></a-select>
      </a-form-model-item>

      <a-form-model-item label="有效时间" prop="beginTime">
        <!--todo-->
        <!--                <a-input placeholder="请输入外部接口地址" v-model="formModel.path"></a-input>-->
        <a-range-picker
          @change="onDateSelected"
          :default-value="[
            moment(formModel.beginTime, dateFormat),
            moment(formModel.endTime, dateFormat),
          ]"
          :format="dateFormat"
        />
      </a-form-model-item>

      <a-form-model-item label="学历要求" prop="educationalBackground">
        <a-select
          v-model="formModel.educationalBackground"
          defaultVlaue="constant"
          :options="options('Education')"
        ></a-select>
      </a-form-model-item>

      <a-form-model-item label="工作背景" prop="handsOnBackground">
        <a-select
          v-model="formModel.handsOnBackground"
          defaultVlaue="constant"
          :options="options('Years')"
        ></a-select>
      </a-form-model-item>

      <a-form-model-item label="性别要求" prop="gender">
        <a-select
          v-model="formModel.gender"
          defaultVlaue="constant"
          :options="options('SexRequired')"
        ></a-select>
      </a-form-model-item>

      <!--                    <a-form-model-item label="工种"   prop="type">-->
      <!--                        <a-select v-model="formModel.type"  defaultVlaue="constant" :options="options('paramType')"></a-select>-->
      <!--                    </a-form-model-item>-->
      <!--                    <a-form-model-item label="结算周期" prop="settlementPeriod">-->
      <!--                        <a-select v-model="item.settlementPeriod"  defaultVlaue="constant" :options="options('paramType')"></a-select>-->
      <!--                    </a-form-model-item>-->

      <a-form-model-item label="描述" prop="description">
        <a-textarea
          v-model="formModel.description"
          placeholder="请输入描述"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="modal-footer">
      <a-button type="danger" @click="cancle">取消</a-button>
      <a-button type="primary" @click="onSubmit">保存</a-button>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { message } from "ant-design-vue";
import moment from "moment";
import EntityHelper from "@/shared/helpers/EntityHelper";
import workOrderApi from "@/shared/service-proxies/workOrder/services/work-order-proxies";
import Bus from "@/shared/bus/bus";
import objectHelper from "@/shared/helpers/objectHelper";

export default {
  name: "sys-interface-form",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      dateFormat: "YYYY/MM/DD",
      fieldsJsonStr: "",
      fieldsJson: {},
      labelCol: {
        span: 6,
        offset: 0,
      },
      interfaceTypes: [],
      requestMethods: [],
      curInternalInterfaceTree: [],
      internalServices: [],
      fieldMaps: {
        requestMethods: {
          value: "id",
          label: "itemName",
        },
      },
      form: {
        layout: "horizontal",
      },
      mode: "create",
      curDataItemTree: [],
      spinning: false,
      // 表单
      formLayout: "horizontal",
      // 选中的权限过滤
      selectedPermission: [],
      formModel: {
        id: "",
        woNo: "",
        woName: "",
        price: 0.0,
        priceBase: "",
        beginTime: "",
        endTime: "",
        educationalBackground: "",
        handsOnBackground: "",
        gender: "",
        type: "",
        settlementPeriod: "",
        description: "",
      },
      validModel: {
        woNo: [{ required: true, message: "请输入工单编号!" }],
        woName: [{ required: true, message: "请输入工单名称!" }],
        price: [{ required: true, message: "请输入工价!" }],
        priceBase: [{ required: true, message: "请选择计价单位!" }],
        beginTime: [{ required: true, message: "请选择开始时间!" }],
        endTime: [{ required: true, message: "请选择结束时间!" }],
        educationalBackground: [{ required: true, message: "请选择教育背景!" }],
        handsOnBackground: [{ required: true, message: "请选择工作经历!" }],
      },
    };
  },

  components: {},
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    let that = this;

    let id = that.formModel.id;
    if (id == "" || typeof id == "undefined") {
      let timezone = 8;
      let offset_GMT = new Date().getTimezoneOffset();
      let nowDate = new Date().getTime();
      let now = new Date(
        nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000
      );
      that.formModel.beginTime = now;
      that.formModel.endTime = now;
      that.formModel.woNo = EntityHelper.newGuid();
      console.log(that.formModel);
    } else {
      console.log(that.formModel);
    }

    //获取系统中的所有内部接口
    // workOrderApi.GetInternalServices().then((internalServices) => {
    //     this.internalServices = internalServices
    // })
  },
  computed: {
    fieldsJsonForPerView() {
      try {
        return JSON.parse(this.fieldsJsonStr);
      } catch (ex) {}
    },
    formItemLayout() {
      const { layout } = this.form;
      return layout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
          }
        : {};
    },
    buttonItemLayout() {
      const { layout } = this.form;
      return layout === "horizontal"
        ? {
            wrapperCol: { span: 14, offset: 4 },
          }
        : {};
    },
  },
  methods: {
    moment,
    onDateSelected(val, val2) {
      this.formModel.beginTime = val[0]._d;
      this.formModel.endTime = val[1]._d;
      console.log(this.formModel.beginTime);
      console.log(this.formModel.endTime);
    },
    cancle() {
      this.close();
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        console.log(valid);
        if (!valid) {
          message.error("表单验证失败，请检查后重新提交！");
          return false;
        }
        workOrderApi
          .save(this.formModel)
          .then((data) => {
            this.$notification["success"]({
              message: "保存成功",
            });

            this.close();
          })
          .catch((error) => {
            console.log("-----oh 我的玉帝 报错了---------");
            console.log(error);
          });
      });
    },
    updateSpinning(newV) {
      if (newV === "0") {
        this.spinning = false;
      } else {
        this.spinning = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  margin-bottom: 0;

  .anticon {
    margin-right: 10px;
  }
}

.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
