<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :actions-type="actionsType"
      :showTotal="showTotalFun"
      :table-data="tableData"
      :tableKey="tableKey"
      :total-items="totalItems"
      :columns="columns"
      :hide-row-selection="true"
      :scroll="{ x: 1200 }"
      :is-full="true"
      :current-page="pageNumber"
      @emitDeleteItem="deleteItem"
      @emitEdit="openForm"
      @emitFieldManage="fieldManage"
      @emitOnPageChange="updatePageChange"
      @emitRefreshData="getData"
      @emitSelectChange="updateSelectChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitSortData="updateSortData"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :actions-type="actionsType"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="getData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8">
        <a-col :span="5" class="gutter-row">
          <a-input
            :placeholder="l('请输入工单名称')"
            v-model.trim="filterText"
            allowClear
          />
        </a-col>
                <a-col class="gutter-row" :span="5">
          <a-range-picker
            :placeholder="[l('起始日期'), l('结束日期')]"
            v-model="startToEndDate"
          />
        </a-col>
        <a-col :span="4" class="gutter-row">
          <a-button
            @click="search"
            type="primary"
            v-if="isGranted('Pages.WorkOrder.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col :span="10" class="gutter-row" style="text-align: right">
          <a-button
            @click="openForm('')"
            type="primary"
            v-if="isGranted('Pages.WorkOrder.Create')"
          >
            <a-icon type="plus" />
            {{ l("Create") }}
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import workOrderApi from "../../../shared/service-proxies/workOrder/services/work-order-proxies";
import EleTable from "@/components/ele-table";
//import {CollectionFormServiceProxy} from "./services/collectionform-proxies";
//import {CommonServiceProxy} from "@/shared/common-service";
import form from "./form.vue";
import objectHelper from "../../../shared/helpers/objectHelper";

import moment from "moment";

export default {
  mixins: [AppComponentBase],
  name: "work-order-index",
  components: { EleTable },
  data() {
    return {
      curDataItemTree: [],
      queryModel: {
        parentId: "",
        name: "",
      },
      columns: [],
      spinning: false,
      // 搜索
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      tableData: [],
      tableKey: "WorkOrderPagedDto",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: {
        sorting: "",
        maxResultCount: 50,
        skipCount: 0,
      },
      _serviceProxy: "",
      commonService: null,
      actionsType: {},
           startToEndDate: [],
    };
  },
  created() {
    this.initActionsType();
    this.getData();
    this.initColumns();
  },
  methods: {
    initColumns() {
      let _this = this;
      this.columns = [
        {
          title: "工单编号",
          dataIndex: "woNo",
          sorter: false,
          align: "center",
          // scopedSlots: { customRender: "taskName" },
        },
        {
          title: "工单名称",
          dataIndex: "woName",
          sorter: false,
          align: "center",
          //  scopedSlots: { customRender: "type" },
        },
        {
          title: "工价",
          dataIndex: "price",
          sorter: true,
          align: "center",
        },
        {
          title: "计价单位",
          dataIndex: "priceBase",
          sorter: false,
          align: "center",
          // customRender: function (text, record, index) {
          //     if (text) {
          //         switch (text) {
          //             case 0:
          //                 return "普通";
          //                 break;
          //             case 1:
          //                 return "重要";
          //                 break;
          //             case 2:
          //                 return "紧急";
          //                 break;
          //             default:
          //                 return "普通";
          //                 break;
          //         }
          //     }
          //     return "";
          // },
          // scopedSlots: { customRender: "level" },
        },
        {
          title: "开始时间",
          dataIndex: "beginTime",
          sorter: false,
          align: "center",
          // fixed: "right",
          customRender: function (text, record, index) {
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: text ? moment(text).format("YYYY-MM-DD") : "",
              },
              attrs: { title: moment(text).format("YYYY-MM-DD") },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
          scopedSlots: { customRender: "beginTime" },
        },
        {
          title: "结束时间",
          dataIndex: "endTime",
          sorter: false,
          align: "center",
          // fixed: "right",
          customRender: function (text, record, index) {
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: text ? moment(text).format("YYYY-MM-DD") : "",
              },
              attrs: { title: moment(text).format("YYYY-MM-DD") },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
          scopedSlots: { customRender: "endTime" },
        },
        {
          title: "学历要求",
          dataIndex: "educationalBackground",
          sorter: true,
          align: "center",
          // fixed: "right",
        },
        {
          title: "工作背景",
          dataIndex: "handsOnBackground",
          sorter: true,
          align: "center",
          // fixed: "right",
        },
        // {
        //     title: '性别要求',
        //     dataIndex: "gender",
        //     sorter: true,
        //     align: "center",
        //     // fixed: "right",
        // },
        // {
        //     title: '结算周期',
        //     dataIndex: "settlementPeriod",
        //     sorter: true,
        //     align: "center",
        //     // fixed: "right",
        // },
        {
          title: "工单状态",
          dataIndex: "status",
          sorter: true,
          align: "center",
          // fixed: "right",
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          width: 100,
          scopedSlots: { customRender: "actions" },
          fixed: "right",
        },
      ];
    },
    search() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 获取数据
     */
    async getData() {
                 var  startDate =!this.startToEndDate[0]? '' : this.startToEndDate[0].format('YYYY-MM-DD');
     var  endDate = !this.startToEndDate[1]? '' : this.startToEndDate[1].format('YYYY-MM-DD');
      this.spinning = true;
      workOrderApi
        .getPagedList({
          startDate:startDate,
          endDate:endDate,
          filterText: this.filterText,
          sorting: this.request.sorting,
          maxResultCount: this.request.maxResultCount,
          skipCount: this.request.skipCount,
        })
        .then((res) => {
          this.spinning = false;
          this.tableData = res.items.map(item=>{ 
            return{
              ...item,
              beginTime: moment(item.beginTime).format("YYYY-MM-DD"),
              endTime: moment(item.endTime).format("YYYY-MM-DD"),
            }
          });
          this.totalItems = res.totalCount;
          // console.log(res);
          // console.log(this.request.maxResultCount);
          this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
          );
          // console.log(this.totalPages);
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
          console.log(this.pagerange);
        })
        .catch((data, error) => {
          this.spinning = false;
        });
      return;
    },
    /**
     * table选择事件
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * table选择事件
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(this.selectedRows);
    },
    /**
     * 分页
     */
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
      this.startToEndDate = [];
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 新建修改
     */
    openForm(param) {
      console.log("openForm!!");
      let mode = "create";
      console.log(param);
      if (param != "") {
        mode = "edit";
        let paramData = objectHelper.deepClone(param);
        param = paramData;
        console.log(param);
      }
      ModalHelper.create(
        form,
        {
          mode: mode,
          formModel: {
            ...param,
          },
        },
        {
          width: "640px",
          isChange: true,
        }
      ).subscribe((res) => {
        console.log("messageRevecived");
        console.log(res);
        this.refreshGoFirstPage();
      });
    },
    /**
     * 清空
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
    },
    /**
     * 单个删除
     */
    deleteItem(item) {
      console.log(item);
      console.log(item.item.id);
      let obj = {};
      obj.id = item.item.id;
      const body = JSON.stringify(obj);
      this.spinning = true;
      this._serviceProxy
        .changeStatus(body)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    /**
     * 导出为excel
     */
    exportToExcel() {
      this.spinning = true;
      this._serviceProxy.getToExcelFile().then((result) => {
        this.spinning = false;
        fileDownloadService.downloadTempFile(result);
      });
    },
    /*
     * 新建文章
     */
    /**
     * emit新建修改
     */
    edit(newV) {
      console.log(newV);
      let { id } = newV;
      this.openForm(id);
    },
    //模板字段管理
    fieldManage(newV) {
      console.log(newV);
      let { id } = newV;
      this.fieldManageForm(id);
    },
    fieldManageForm(id) {
      ModalHelper.create(
        FieldManageForm,
        {
          id: id,
        },
        {
          width: "400px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    /**
     * 排序更新data
     */
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 分页
     */
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    /**
     * table选择事件
     */
    updateSelectChange(newV) {
      console.log(newV);
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },

    closeWorkOrder(data) {
      workOrderApi.close(data.id).then((result) => {
        console.log(result);
        this.$notification["success"]({
          message: "关闭成功！",
        });
        this.getData();
      });
    },
    takeWorkOrder(data) {
      workOrderApi.take(data.id).then((result) => {
        console.log(result);
        this.$notification["success"]({
          message: "接单成功！",
        });
        this.getData();
      });
    },
    /**
     * 初始化需要显示的func btn
     */
    initActionsType() {
      let _that = this;
      let obj = {
        type: "work-order-action",
        isShow: true,
        fns: {
          dropList: [],
          detailList: [
            {
              granted: true,
              name: "接单",
              icon: "setting",
              // width: "50%",
              showFunc(record) {
                // console.log(record);
                return !record.beenTaken;
              },
              fn: (data) => {
                _that.takeWorkOrder(data);
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}

.pagination {
  margin: 10px auto;
  text-align: right;
}

.table-plus,
.table-delete {
  margin-left: 10px;
}
</style>
